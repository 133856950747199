
  .themeButton {
    font-weight: 700 !important;
    text-transform: capitalize !important;
  }
  
  .themeButtonContained {
    color: #FFF !important;  
    background-color: #0047A9 !important;
    /* padding 10px to maintain height */
    padding: 10px !important;
    font-size: 16px !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 12px 2px rgb(87 151 169 / 25%) !important;
  }
  
  .disableSubmitButton {
    color: #FFF !important;  
    background-color: #CCCCCC !important;
    /* padding 10px to maintain height */
    padding: 10px !important;
    font-size: 16px !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    cursor: not-allowed !important;
    pointer-events: auto;
  }

  /* ~~~~~~~~~~~~~ALL THE SUBMIT BUTTONS ON PAGE END IN BLACK~~~~~~~~~~~~~ */
  .submitBlackButton {
    margin-top: 24px !important; 
    height: 48px !important; 
    background: #0047A9 !important; 
    font-size: 14px !important;
    font-weight: bold !important;
    border-radius: 8px !important;
    text-transform: capitalize !important;
    color: #FFF !important;
  }
  
  .submitGreyButton {
    margin-top: 24px !important; 
    height: 48px !important; 
    background: rgb(192, 192, 192) !important; 
    font-size: 14px !important;
    font-weight: bold !important;
    border-radius: 8px !important;
    text-transform: capitalize !important;
    color: #FFF !important;
  }
  
  .submitBlackButtonPopUp {
    height: 48px !important; 
    background: #0047A9 !important; 
    font-size: 14px !important;
    font-weight: bold !important;
    border-radius: 8px !important;
    text-transform: capitalize !important;
    color: #FFF !important;
  }
  